<template>
  <div>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <!-- <canvas  height="350" width="400" ref="first"></canvas > -->
        <!-- <canvas  height="350" width="400" ref="first" style="background-position: 27px 0px; background: url(https://backendsimpus-ujrvfrlb2a-et.a.run.app/kmsl.png) no-repeat center;height: 5cm; width: 8cm;"></canvas > -->
        <canvas  height="350" width="400" ref="first" style="background-position: 27px 0px; background: url(https://backendsimpus-ujrvfrlb2a-et.a.run.app/kmsl.png) no-repeat center; background-size: cover;"></canvas >
        <!-- background-size: cover; -->
        <!-- height: 5cm; width: 8cm; -->
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <!-- <canvas  height="350" width="400" ref="second"></canvas > -->
        <!-- <canvas  height="350" width="400" ref="second" style="background-position: 27px 0px; background: url(https://backendsimpus-ujrvfrlb2a-et.a.run.app/kmsLG.png) no-repeat center; background-size: cover;"></canvas > -->
        <!-- background-size: cover; -->
        <!-- height: 5cm; width: 8cm; -->
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineController
} from 'chart.js'
import 'chartjs-adapter-moment';
ChartJS.register(Title, Tooltip, Legend, LineController, PointElement,LineElement, CategoryScale, LinearScale, TimeScale)
export default {
  props:[
    'data_pasien',
  ],
  data() {
    return {
      myChart: null,
      listRiwayatPenimbangan: [],
    }
  },
  computed: {
    
  },
  mounted() {
    const vm = this
    const image = new Image();
    image.src = 'https://www.chartjs.org/img/chartjs-logo.svg';
    vm.myChart = new ChartJS(this.$refs.first, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
        datasets: [{
          lineTension: 0,
          fill: false,
          label: 'Berat Badan',
          labelColor: 'black',
          data: ['', '','','','','','','','','','','','','','','','','','','','','','','',''],
          borderColor: 'black',
          borderWidth: 2,
          pointBorderWidth: 5,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 1,
          pointRadius: 1,
        }]
      },
      options: {
        scales: {
          x: {
            gridLines: {
              color: "black"
            },
            ticks: {
              max: 24,
              fontColor: "black",
              min: 0,
              stepSize: 1
            }
          },
          y: {
            gridLines: {
              color: "black"
            },
            ticks: {
              fontColor: "black",
              stepSize: 1
            },
              max: 18,
              min: 0,
            label: 'Right dataset'
          }
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'grey',
            boxWidth: 5
          }
        },
      
      },
      // plugins: {
      //   id: 'customCanvasBackgroundImage',
      //   beforeDraw: (chart) => {
      //     if (image.complete) {
      //       const ctx = chart.ctx;
      //       const {top, left, width, height} = chart.chartArea;
      //       const x = left + width / 2 - image.width / 2;
      //       const y = top + height / 2 - image.height / 2;
      //       console.log('jalan')
      //       ctx.drawImage(image, x, y);
      //     } else {
      //       image.onload = () => chart.draw();
      //     }
      //   }
      // }
    });
    // vm.myChart.plugins.register({
    //   beforeDraw: function(chartInstance) {
    //     var ctx = chartInstance.chart.ctx;
    //     ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
    //     ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
    //     var img = new Image();
    //     img.src = 'https://www.chartjs.org/img/chartjs-logo.svg';
    //     img.onload = function() {
    //       ctx.drawImage(img, 0, 0, chartInstance.chart.width, chartInstance.chart.height);
    //     };
    //   }
    // });
    vm.myChart.update()

    vm.getDatas()
  },
  methods: {
    async getDatas(){
      const vm = this
      vm.busy = true
      try {
        if (vm.data_pasien.rm_id) {
          const listRiwayatPenimbangan = await vm.$axios.post('/riwayat_penimbangan/list', {
            rm_id: vm.data_pasien.rm_id
          })
          console.log('riwayat_penimbangan ', listRiwayatPenimbangan)
          if(listRiwayatPenimbangan.data.status == 200){
            vm.listRiwayatPenimbangan = listRiwayatPenimbangan.data.data.sort((a, b) => a.usia_bulan - b.usia_bulan);
            console.log('vm.listRiwayatPenimbangan ', vm.listRiwayatPenimbangan)
            const data = []
            for (let i = 0; i < vm.listRiwayatPenimbangan.length; i++) {
              const x = vm.listRiwayatPenimbangan[i];
              data.push({
                y: x.berat_badan,
                x: x.usia_bulan,
              })
            }
            vm.myChart.data.datasets[0].data = data;
            vm.myChart.update()
          }
        }
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false;
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    }
  },
}
</script>
