<template>
  <div style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Penimbangan Balita
            </h6>
          </div>
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="4">
          <div style="display: flex; justify-content: flex-end">
            <b-button variant="primary  " @click="$bvModal.show('modal-rekam-medis')" :disabled="busy"> 
              <h6 class="m-0 p-0 text-light">
                <b-icon icon="list-task"></b-icon>
                    List Rekam Medis
                </h6>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>

      <b-row class="pb-4">
        <b-col cols="4">
          <b-row class="m-0 p-3 card">
            <b-col cols="12" sm="12" md="12" lg="12">
              <h5 class="custom-title-card"><strong>Data Pasien</strong></h5>
            </b-col>
            <b-col cols="12">
              <b-row class="" style="margin: 0px -8px;">
                <b-col cols="5" class="fw-600">No RM</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.no_rm || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Nama pasien</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.nama_lengkap || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Jenis kelamin</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.nama_jenis_kelamin || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Tinggi Badan</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.tinggi_badan || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Berat Badan</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.berat_badan || "-" }}</b-col>
                <b-col cols="5" class="fw-600">IMD</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.imd || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Umur</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.umur || "-" }}</b-col>
                <b-col cols="5" class="fw-600">NIK</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.nik || "-" }}</b-col>
                <b-col cols="5" class="fw-600">No BPJS</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.no_bpjs || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Alamat</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.alamat_sekarang || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Nama Ayah</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.nama_ayah || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Nama Ibu</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.nama_ibu || "-" }}</b-col>
                <b-col cols="5" class="fw-600">Penjamin</b-col>
                <b-col cols="7">: {{ dataForm.data_pasien.nama_penjamin || "-" }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- <pre>{{dataForm.data_pasien}}</pre> -->
        </b-col>
        <b-col v-if="dataForm.data_pasien.rm_id" cols="8" style="padding-left: 0px;">
          <div class="card p-3">
            <b-row class="mr-0" style="margin: 0px 0px;">
              <b-col cols="12" sm="12" md="12" lg="12">
                <h5 class="custom-title-card"><strong>Data Form</strong></h5>
              </b-col>
              <b-col cols="6">
                <label for="dokter_id">Dokter <span class="text-danger">*</span></label>
                <Multiselect
                  id="dokter_id"
                  v-model="$v.dataForm.dokter_id.$model"
                  :options="listDokter"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nama_tenaga_medis"
                  track-by="tenaga_medis_id"
                  placeholder="-- Pilih Dokter --"
                  size="sm"
                ></Multiselect>
              </b-col>
              <b-col cols="6">  
                <label for="perawat_id">Perawat</label>
                <Multiselect
                  id="perawat_id"
                  v-model="$v.dataForm.perawat_id.$model"
                  :options="listPerawat"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nama_tenaga_medis"
                  track-by="tenaga_medis_id"
                  placeholder="-- Pilih Perawat --"
                  size="sm"
                ></Multiselect>
              </b-col>
              <b-col cols="6">
                <label for="tanggal_penimbangan">Tanggal <span class="text-danger">*</span></label>
                <date-picker
                  style="width: 100%"
                  id="tanggal_penimbangan"
                  format="DD-MM-YYYY"
                  v-model="$v.dataForm.tanggal_penimbangan.$model"
                  disabled
                  :state="checkIfValid('dataForm', 'tanggal_penimbangan')"
                ></date-picker>
              </b-col>
              <b-col cols="6">
                <label for="anak_ke">Anak <span class="text-danger">*</span></label>
                <b-form-input
                  id="anak_ke"
                  type="number"
                  :state="checkIfValid('dataForm', 'anak_ke')"
                  v-model="$v.dataForm.anak_ke.$model"
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for="usia_bulan">Usia <span class="text-danger">*</span></label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="usia_bulan"
                    type="number"
                    :state="checkIfValid('dataForm', 'usia_bulan')"
                    v-model="$v.dataForm.usia_bulan.$model"
                    @change="changeUsiaBulan"
                    :disabled="!(!dataForm.id)"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>bulan</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="6">
                <label for="bulan_timbang">Bulan</label>
                <b-form-input
                  id="bulan_timbang"
                  type="text"
                  v-model="dataForm.bulan_timbang"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label for="berat_badan">Berat Badan <span class="text-danger">*</span></label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="berat_badan"
                    type="number"
                    :state="checkIfValid('dataForm', 'berat_badan')"
                    v-model="$v.dataForm.berat_badan.$model"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>kg</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="6">
                <label for="tinggi_badan">Tinggi Badan <span class="text-danger">*</span></label>
                <b-input-group class="w-100">
                  <b-form-input
                    id="tinggi_badan"
                    type="number"
                    :state="checkIfValid('dataForm', 'tinggi_badan')"
                    v-model="$v.dataForm.tinggi_badan.$model"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button>cm</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="6">
                <label for="n_t">N/T</label>
                <Multiselect
                  id="n_t"
                  v-model="$v.dataForm.n_t.$model"
                  :options="$store.state.data_static.n_t"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="-- Pilih N/T --"
                  size="sm"
                ></Multiselect>
              </b-col>
              <b-col cols="6">
                <label for="bb_u">BB/U <span class="text-danger">*</span></label>
                <Multiselect
                  id="bb_u"
                  v-model="$v.dataForm.bb_u.$model"
                  :options="$store.state.data_static.bb_u"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="-- Pilih BB/U --"
                  size="sm"
                ></Multiselect>
              </b-col>
              <b-col cols="6">
                <label for="tb_u">TB/U <span class="text-danger">*</span></label>
                <Multiselect
                  id="tb_u"
                  v-model="$v.dataForm.tb_u.$model"
                  :options="$store.state.data_static.tb_u"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="-- Pilih TB/U --"
                  size="sm"
                ></Multiselect>
              </b-col>
              <b-col cols="6">
                <label for="bb_tb">BB/TB <span class="text-danger">*</span></label>
                <Multiselect
                  id="bb_tb"
                  v-model="$v.dataForm.bb_tb.$model"
                  :options="$store.state.data_static.bb_tb"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="-- Pilih BB/TB --"
                  size="sm"
                ></Multiselect>
              </b-col>
              <b-col cols="6">
                <label for="vit_a">Vitamin A</label>
                <Multiselect
                  id="vit_a"
                  v-model="$v.dataForm.vit_a.$model"
                  :options="$store.state.data_static.vitamin_a"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="-- Pilih Vitamin A --"
                  size="sm"
                ></Multiselect>
              </b-col>
              <b-col cols="4">
                <label for="cara_ukur">Cara Ukur <span class="text-danger">*</span></label>
                <div style="margin-left: 10px;">
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="cara_ukur"
                      v-model="$v.dataForm.cara_ukur.$model"
                      :aria-describedby="ariaDescribedby"
                      name="cara_ukur"
                    >
                      <b-form-radio value="Terlentang"><div style="margin-top: 3px;">Terlentang</div></b-form-radio>
                      <b-form-radio value="berdiri"><div style="margin-top: 3px;">berdiri</div></b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="4">
                <label for="pmt">PMT</label>
                <div style="margin-left: 10px;">
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="pmt"
                      v-model="$v.dataForm.pmt.$model"
                      :aria-describedby="ariaDescribedby"
                      name="pmt"
                    >
                      <b-form-radio :value="true"><div style="margin-top: 3px;">Ya</div></b-form-radio>
                      <b-form-radio :value="false"><div style="margin-top: 3px;">Tidak</div></b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="4">
                <label for="obat_cacing">Obat Cacing</label>
                <div style="margin-left: 10px;">
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="obat_cacing"
                      v-model="$v.dataForm.obat_cacing.$model"
                      :aria-describedby="ariaDescribedby"
                      name="obat_cacing"
                    >
                      <b-form-radio :value="true"><div style="margin-top: 3px;">Ya</div></b-form-radio>
                      <b-form-radio :value="false"><div style="margin-top: 3px;">Tidak</div></b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="12">
                <label for="konseling">Konseling</label>
                <b-form-textarea
                  id="konseling"
                  :state="checkIfValid('dataForm', 'konseling')"
                  v-model="$v.dataForm.konseling.$model"
                ></b-form-textarea>
              </b-col>
            <!-- </b-row>
          </div>
          <div class="card p-3">
            <b-row class="mr-0" style="margin: 0px 0px;"> -->
              <b-col cols="12" sm="12" md="12" lg="12">
                <h5 class="custom-title-card mt-2"><strong>Imunisasi</strong></h5>
              </b-col>
              <b-col cols="4" v-for="(item, key) of listImunisasi" :key="key">
                <b-form-checkbox
                  :id="'imunisasi-' + key"
                  v-model="listImunisasi[key].value"
                  :name="'imunisasi-' + key"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="item.rm_id != null || dataForm.id != undefined"
                >
                  <div style="margin-top: 2px;">
                    {{item.nama_imunisasi}}
                  </div>
                </b-form-checkbox>
              </b-col>
              <b-col cols="12">
                <div class="d-flex justify-content-end">
                  <b-button 
                    class="mr-2"
                    :disabled="busy"
                    @click="reset()"
                    variant="danger"
                  >Reset</b-button>
                  <b-button 
                    v-if="dataForm.id"
                    :disabled="busy"
                    @click="update()"
                    variant="warning"
                  >Update</b-button>
                  <b-button
                    v-else
                    :disabled="busy"
                    @click="simpan()"
                    variant="primary"
                  >Simpan</b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col v-if="dataForm.data_pasien.rm_id" cols="12" style="">
          <div class="card p-3">
            <b-row class="" style="margin: 0px 0px;">
              <b-col cols="12" sm="12" md="12" lg="12">
                <h5 class="custom-title-card"><strong>List Penimbangan Balita</strong></h5>
              </b-col>
              <b-col cols="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  responsive
                  show-empty
                  small
                  bordered
                  striped
                  hover
                  :busy="busy"
                >
                  <template #cell(no)="item">{{item.index+1}}</template>
                  <template #cell(actions)="item">
                    <div class="d-flex nowrap">
                      <b-button
                        variant="warning"
                        size="sm"
                        v-c-tooltip.hover.click="'Update Data'"
                        @click="onUpdate(item.item)"
                        ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                      >
                      <b-button
                        variant="danger"
                        size="sm"
                        class="ml-1"
                        v-c-tooltip.hover.click="'Hapus Data'"
                        v-b-modal.modal-delete-penimbangan-balita
                        @click="is_data = item.item"
                        ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                      >
                    </div>
                  </template>
                </b-table>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="12">
                <h5 class="custom-title-card"><strong>Grafik & Isian Kartu Menuju Sehat (KMS)</strong></h5>
                <MyChart :data_pasien="dataForm.data_pasien"/>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <ModalRekamMedis
      key_modal="modal-rekam-medis"
      @pilihPasien="pilihPasien"
    />
    <ModalDelete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getData()"
    />
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ModalRekamMedis from '../pendaftaran/modal_pasien.vue'
import ModalDelete from "./modalDelete.vue";
import MyChart from "./chart.vue"
export default {
  components: {
    ModalDelete,
    ModalRekamMedis,
    MyChart,
  },
  data() {
    return {
      busy: false,
      is_data: {},
      dataForm: {
        data_pasien: {},
        tanggal_penimbangan: new Date(),
        usia_bulan: null,
        berat_badan: null,
        cara_ukur: 'terlentang',
        tinggi_badan: null,
        n_t: null,
        tb_u: null,
        bb_u: null,
        bb_tb: null,
        vit_a: null,
        konseling: null,
        anak_ke: null,
        pmt: false,
        obat_cacing: false,
        penambahan_imunisasi: null,
        dokter_id: null,
        perawat_id: null,
        bulan_timbang: null,
      },
      listDokter: [],
      listPerawat: [],
      listImunisasi: [],
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_tanggal",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_dokter",
          label: "Dokter",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_perawat",
          label: "Perawat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "usia_bulan",
          label: "Usia",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
    }
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      data_pasien: { required },
      tanggal_penimbangan: { required },
      usia_bulan: { required },
      berat_badan: { required },
      cara_ukur: { required },
      tinggi_badan: { required },
      n_t: {  },
      tb_u: { required },
      bb_u: { required },
      bb_tb: { required },
      vit_a: {  },
      konseling: {  },
      anak_ke: { required },
      pmt: {  },
      obat_cacing: {  },
      penambahan_imunisasi: {  },
      dokter_id: { required },
      perawat_id: {  },
    },
  },
  computed: {
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
  },
  mounted() {
    const vm = this
    vm.$bvModal.show('modal-rekam-medis')
    vm.getList()
    vm.getData()
    // vm.getPasien()
  },
  methods: {
    changeUsiaBulan(){
      const vm = this
      // console.log('vm.dataForm.data_pasien', vm.dataForm.data_pasien)
      if(vm.dataForm.usia_bulan == null || (vm.dataForm.data_pasien && vm.dataForm.data_pasien.tanggal_lahir == null)){
        // console.log(1);
        vm.dataForm.bulan_timbang = null
      }else{
        // console.log(2);
        vm.dataForm.bulan_timbang = vm.$moment(vm.dataForm.data_pasien.tanggal_lahir).add(vm.dataForm.usia_bulan, 'months').format('MMMM')
      }
    },
    async pilihPasien(data){
      const vm = this
      vm.busy = true
      try {
        await vm.reset()
        console.log('data pilih pasien', data)
        data.nama_jenis_kelamin = data.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' 
        let expired = data.tanggal_lahir ? vm.$moment(data.tanggal_lahir) : vm.$moment()
        let now = vm.$moment()
        let hasil = vm.$moment.duration(now.diff(expired))
        data.umur = `${hasil.years()} tahun ${hasil.months()} bulan ${hasil.days()} hari`
        vm.dataForm.data_pasien = {
          // ...data,
          rm_id: data.rm_id,
          no_rm: data.no_rm,
          nama_lengkap: data.nama_lengkap,
          tanggal_lahir: data.tanggal_lahir,
          nama_jenis_kelamin: data.nama_jenis_kelamin,
          tinggi_badan: data.tinggi_badan,
          berat_badan: data.berat_badan,
          imd: data.imd,
          umur: data.umur,
          nik: data.nik,
          no_bpjs: data.no_bpjs,
          alamat_sekarang: data.alamat_sekarang,
          nama_ayah: data.nama_ayah,
          nama_ibu: data.nama_ibu,
          nama_penjamin: data.nama_penjamin,
        }
        // vm.dataForm.rm_id = data.rm_id
        vm.dataForm.usia_bulan = now.diff(expired, "months")
        vm.getData()
        vm.changeUsiaBulan()
        //kartu bayi
        let kartuBayi = await vm.$axios.post('/kartu_bayi/list', {rm_id: data.rm_id})
        console.log('kartuBayi', kartuBayi)
        if(kartuBayi.data.status == 200 && kartuBayi.data.data.length){
          const x = kartuBayi.data.data[0]
          vm.dataForm.data_pasien.tinggi_badan = x.panjang_badan
          vm.dataForm.data_pasien.berat_badan = x.berat_badan
          vm.dataForm.data_pasien.imd = x.imd
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getData(){
      const vm = this
      vm.busy = true
      try {
        if(vm.dataForm.data_pasien.rm_id){
          //penimbangan_balita
          let items = await vm.$axios.post('/penimbangan_balita/list', {rm_id: vm.dataForm.data_pasien.rm_id})
          console.log('penimbangan_balita', items)
          vm.items = items.data.status == 200 ? items.data.data.map(x=>{
          return {
              ...x,
              id: x.penimbangan_balita_id,
              nama_tanggal: x.tanggal_penimbangan ? vm.$moment(x.tanggal_penimbangan).format('LL') : '-',
            }
          }) : []
          vm.getPasien()
        }
        await vm.changeUsiaBulan()
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getPasien(){
      const vm = this
      vm.busy = true
      try {
        if(vm.dataForm.data_pasien.rm_id){
          //rekam medis
          let rm = await vm.$axios.post('/rm/list', {rm_id: vm.dataForm.data_pasien.rm_id})
          console.log('rekam medis', rm)
          if(rm.data.status == 200 && rm.data.data.length > 0 && rm.data.data[0].riwayat_imunisasi){
            const listImunisasi = JSON.parse(JSON.stringify(vm.listImunisasi))
            for (let i = 0; i < rm.data.data[0].riwayat_imunisasi.length; i++) {
              const x = rm.data.data[0].riwayat_imunisasi[i];
              let u = 0
              for (; u < listImunisasi.length; u++) {
                const y = listImunisasi[u];
                if(y.nama_imunisasi == x.nama_imunisasi) {
                  listImunisasi[u] = {...x, value: true}
                  break
                }
              }
              if(u == listImunisasi.length) {
                listImunisasi.push({...x, value: true})
              }
            }
            vm.listImunisasi = []
            vm.listImunisasi = listImunisasi
            // console.log('listImunisasi', vm.listImunisasi)
          }
          // }) : []
        }
        await vm.changeUsiaBulan()
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getList(){
      const vm = this
      vm.busy = true
      try {
        //dokter
        let listDokter = await vm.$axios.post('/tenaga_medis/list', {nama_jenis_tenaga_medis: 'dokter'})
        vm.listDokter = listDokter.data.status == 200 ? listDokter.data.data : []
        //perawat
        let listPerawat = await vm.$axios.post('/tenaga_medis/list', {nama_jenis_tenaga_medis: 'perawat'})
        vm.listPerawat = listPerawat.data.status == 200 ? listPerawat.data.data : []
        //imunisasi
        let listImunisasi = await vm.$axios.post('/ms_imunisasi/list')
        vm.listImunisasi = listImunisasi.data.status == 200 ? listImunisasi.data.data.map(x=>{
          return {
            ...x,
            value: false,
          }
        }) : []
        // console.log('listImunisasi', listImunisasi)
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async simpan(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let penambahan_imunisasi = []
          for (let i = 0; i < vm.listImunisasi.length; i++) {
            const x = vm.listImunisasi[i];
            if(x.value && !x.rm_id){
              penambahan_imunisasi.push(x)
            }
          }
          let res = await vm.$axios.post('/penimbangan_balita/register', {
            ...vm.dataForm,
            rm_id: vm.dataForm.data_pasien.rm_id,
            penambahan_imunisasi,
            dokter_id: vm.dataForm.dokter_id ? vm.dataForm.dokter_id.tenaga_medis_id : null,
            perawat_id: vm.dataForm.perawat_id ? vm.dataForm.perawat_id.tenaga_medis_id : null,
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.$store.commit("set_alert", { variant: "success", msg: 'Berhasil Membuat Penimbangan Bayi', showing: true });
            await vm.reset()
            vm.getData()
            // vm.getList()
          }else{
            vm.$store.commit("set_alert", { variant: "danger", msg: 'Gagal Membuat Penimbangan Bayi', showing: true });
          }
        }else{
          vm.$store.commit("set_alert", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$store.commit("set_alert", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$store.commit("set_alert", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async onUpdate(data){
      const vm = this
      console.log('onUpdate', data)
      await vm.reset()
      vm.dataForm = {
        id: data.penimbangan_balita_id,
        data_pasien: vm.dataForm.data_pasien,
        tanggal_penimbangan: new Date(data.tanggal_penimbangan),
        usia_bulan: data.usia_bulan,
        berat_badan: data.berat_badan,
        cara_ukur: data.cara_ukur,
        tinggi_badan: data.tinggi_badan,
        n_t: data.n_t,
        tb_u: data.tb_u,
        bb_u: data.bb_u,
        bb_tb: data.bb_tb,
        vit_a: data.vit_a,
        konseling: data.konseling,
        anak_ke: data.anak_ke,
        pmt: data.pmt,
        obat_cacing: data.obat_cacing,
        penambahan_imunisasi: data.penambahan_imunisasi,
        // dokter_id: data.dokter_id,
        // perawat_id: data.perawat_id,
      }
      vm.dataForm.dokter_id = vm.$findKey(vm.listDokter, data.dokter_id, true, 'tenaga_medis_id')
      vm.dataForm.perawat_id = vm.$findKey(vm.listPerawat, data.perawat_id, true, 'tenaga_medis_id')
      await vm.getList()
      await vm.getPasien()
      await vm.changeUsiaBulan()
    },
    async update(){
      const vm = this
      vm.busy = true
      try {
        await this.$v.dataForm.$touch();
        if (vm.isValid && vm.isDirty) {
          let res = await vm.$axios.post('/penimbangan_balita/update', {
            ...vm.dataForm,
            rm_id: vm.dataForm.data_pasien.rm_id,
            dokter_id: vm.dataForm.dokter_id ? vm.dataForm.dokter_id.tenaga_medis_id : null,
            perawat_id: vm.dataForm.perawat_id ? vm.dataForm.perawat_id.tenaga_medis_id : null,
          })
          // console.log('register diagnosa', res)
          if(res.data.status == 200){
            vm.$store.commit("set_alert", { variant: "success", msg: 'Berhasil Update Penimbangan Bayi', showing: true });
            await vm.reset()
            vm.getData()
            // vm.getList()
          }else{
            vm.$store.commit("set_alert", { variant: "danger", msg: 'Gagal Update Penimbangan Bayi', showing: true });
          }
        }else{
          vm.$store.commit("set_alert", { variant: "warning", msg: 'Data Tidak Valid', showing: true });
        }
      } catch (error) {
        console.log(error)
        if (error.message) {
          vm.$store.commit("set_alert", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
        } else {
          vm.$store.commit("set_alert", { variant: "danger", msg: error.toUpperCase(), showing: true });
        }
      } finally {
        vm.busy = false
      }
    },
    async reset(){
      const vm = this
      vm.dataForm = {
        data_pasien: vm.dataForm.data_pasien,
        tanggal_penimbangan: new Date(),
        usia_bulan: vm.dataForm.usia_bulan,
        berat_badan: null,
        cara_ukur: 'terlentang',
        tinggi_badan: null,
        n_t: null,
        tb_u: null,
        bb_u: null,
        bb_tb: null,
        vit_a: null,
        konseling: null,
        anak_ke: null,
        pmt: false,
        obat_cacing: false,
        penambahan_imunisasi: null,
        dokter_id: null,
        perawat_id: null,
        bulan_timbang: null,
      }
      vm.$v.$reset()
      // vm.getList()
      // vm.getPasien()
      // vm.listImunisasi = {}
    },
    checkIfValid(name, fieldName) {
      // console.log('name', name)
      // console.log('fieldName', fieldName)
      const field = this.$v[name][fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>